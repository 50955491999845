import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, PageProps } from 'gatsby'
// Types
import { GatsbyImageBasicProps } from '../types/images'
// Components
import { Link } from 'gatsby'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Text, Image, Button } from '../atoms'
import { Layout } from '../components/commons'
import { SECTION_BOTTOM_PADDING, SECTION_TOP_PADDING } from '../utils/constants'
import { StaticImage } from 'gatsby-plugin-image'

interface NotFoundQuery {
  notFound: {
    headline: string
    content: string
    buttonLabel: string
    asset: GatsbyImageBasicProps
  }
}

const NotFoundPage: React.FC<PageProps<NotFoundQuery>> = ({ data }) => {
  const {
    notFound: { headline, content, buttonLabel, asset },
  } = data

  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>

      <Box overflow={'hidden'}>
        <Grid>
          <Box position={'relative'} pt={SECTION_TOP_PADDING} pb={SECTION_BOTTOM_PADDING}>
            <Box display={['none', 'none', 'block']} position={'absolute'} top={0} left={-150} zIndex={0}>
              <StaticImage src={'../images/ellipse_tr.png'} alt={''} placeholder={'none'} width={255} />
            </Box>
            <Box display={['none', 'none', 'block']} position={'absolute'} bottom={80} right={-400} zIndex={0}>
              <StaticImage src={'../images/ellipse_bl.png'} alt={''} placeholder={'none'} width={500} />
            </Box>
            <Box display={['none', 'none', 'block']} position={'absolute'} bottom={-80} left={-247} zIndex={0}>
              <StaticImage src={'../images/grid.png'} alt={''} placeholder={'none'} width={247} />
            </Box>
            <Box position={'relative'} zIndex={1}>
              <Row center={'xs'} middle={'xs'} reverse={true}>
                {asset && (
                  <Col xs={12} sm={10} md={6}>
                    <Box pl={[0, 0, 0, 8]}>
                      <Image
                        alt={asset.alt || headline}
                        title={asset.title || headline}
                        image={asset.gatsbyImageData}
                      />
                    </Box>
                  </Col>
                )}
                <Col xs={12} sm={10} md={6}>
                  <Box my={6} pr={[0, 0, 0, 8]}>
                    <Heading as={'h1'} type={'display'} whiteSpace={'pre-line'}>
                      {headline}
                    </Heading>
                    {!!content && (
                      <Box mt={6}>
                        <Text type={'large'} whiteSpace={'pre-line'}>
                          {content}
                        </Text>
                      </Box>
                    )}
                    <Box mt={6}>
                      <Link to='/' style={{ display: 'block' }}>
                        <Button buttonType={'secondary'} label={buttonLabel || 'Back to home'} />
                      </Link>
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  )
}

export const query = graphql`
  query NotFoundQuery {
    notFound: datoCmsGlobal {
      headline: notfoundHeadline
      content: notfoundContent
      buttonLabel: notfoundButtonLabel
      asset: notfoundAsset {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
      }
    }
  }
`

export default NotFoundPage
